$rccs-size: 100px;
$rccs-card-ratio: 1.588;
$rccs-name-font-size: 6px;
$rccs-number-font-size: 6px;
$rccs-expiry-font-size: 6px;
$rccs-valid-font-size: 3.5px;

html, body, #root, .App {
  height: auto;
  // background-color: #fafdfe;
}

h1, h2, h3, h4, h5 {
  // font-weight: 200 !important
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.text-align-left {
  text-align: left
}

.text-align-right {
  text-align: right;
}

.show-print {
  display: none !important;
}

.z-index-0 {
  .MuiPaper-root {
    z-index: 0;
  }
}

a:hover {
  text-decoration: none !important;
}

.sigCanvas {
  display: block;
  width: 100%;
  height: 150px;
  border-bottom: 1px solid darkgrey;
  border-left: 1px dashed lightgrey;
  border-top: 1px dashed lightgrey;
  border-right: 1px dashed lightgrey;
  border-radius: 5px;
}

.signature-container {
  border-left: 1px solid darkgrey;
  border-top: 1px solid darkgrey;
  border-right: 1px solid darkgrey;
  border-radius: 5px;
}

@media print {
  .hide-print {
    display: none !important;
  }
  .show-print {
    display: block !important;
  }
  .container {
    max-width: 100%;
  }
  .print-full-width {
    width: 100% !important;
  }
}

@media only screen and (device-width: 390px) 
and (device-height: 844px) { 
  .hide-mobile {
    display: none !important;
  }
}
