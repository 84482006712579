@media print {
  .container {
    padding: 0 !important;
  }
  .MuiContainer-maxWidthLg {
    max-width: 100% !important;
    padding: 0 !important;
  }
}

.scanner-print-container {
  position: relative;
  margin: 0;
  max-width: 100%;
  width: 100%;
  display: none;
  @media print {
    display: block;
    .ticket-page {
      width: 100%;
      break-inside: avoid;
      break-after: always;
      display: table;
      position: relative;
      .scanner-ticket {
        height: 4.4in;
        border: 1px dashed lightgray;
        width: 50%;
        padding: .25in;
        .text-align-right {
          text-align: right;
        }
      }
    }
    .text-align-right {
      margin-left: auto;
    }
  }
}